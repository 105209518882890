<template>
<div class="d-flex flex-column flex-root">
    <!--begin::Login-->
    <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
        <!--begin::Aside-->
        <div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #2f3742;z-index: 2000;">
            <!--begin::Aside Top-->
            <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
                <!--begin::Aside header-->
                <a href="#" class="text-center mb-10">
                    <img src="assets/media/logos/LOGOWHITE.png" class="max-h-150px" alt="">
                </a>
                <!--end::Aside header-->
                <!--begin::Aside title-->
               
                <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg text-white" style="color:a2a2a2;">Free Crypto Trading Signals<br/>AI Powered Trading Bot
                <br></h3>
                <!--end::Aside title-->
            </div>
            <!--end::Aside Top-->
            <!--begin::Aside Bottom-->
            <div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" style="background-size: 250%; background-position: center; background-image: url(&quot;https://images.pexels.com/photos/241544/pexels-photo-241544.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500&quot;);margin-top: -30rem;z-index: -1;opacity: 0.05;"></div>
            <!--end::Aside Bottom-->
        </div>
        <!--begin::Aside-->
        <!--begin::Content-->
        <div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
            <!--begin::Content body-->
            <div class="d-flex flex-column-fluid flex-center">
                <!--begin::Signin-->
                <div v-show="tab == 'login'" class="login-form login-signin">
                    <!--begin::Form-->
                    <div>
                        <!--begin::Title-->
                        <div class="pb-13 pt-lg-0 pt-5">
                            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Welcome to TrustSignals</h3>
                            <span class="text-muted font-weight-bold font-size-h4">New Here? 
                            <a @click="tab = 'register'" id="kt_login_signup" class="text-primary font-weight-bolder">Create an Account</a></span>
                        </div>
                        <!--begin::Title-->
                        <div class="form-group">
                            <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
                            <input v-model="login_email" class="form-control form-control-solid h-auto py-6 px-6 rounded-lg" type="text" name="email" autocomplete="off" />
                        </div>
                        <!--end::Form group-->
                        <!--begin::Form group-->
                        <div class="form-group">
                            <div class="d-flex justify-content-between mt-n5">
                                <label class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
                            </div>
                            <input v-model="login_password" class="form-control form-control-solid h-auto py-6 px-6 rounded-lg" type="password" name="password" autocomplete="off" />
                        </div>
                        <!--end::Form group-->
                        <!--begin::Form group-->
                        <div v-show="twoFA_required" class="form-group">
                            <label class="font-size-h6 font-weight-bolder text-dark pt-5">Codice 2FA</label>
                            <input v-model="TwoFA" class="form-control form-control-solid h-auto py-6 px-6 rounded-lg" type="password" autocomplete="off" />
                        </div>
                        <div class="form-group d-flex flex-wrap justify-content-between align-items-center mt-3">
                            <div class="checkbox-inline">
                                <label class="checkbox checkbox-outline m-0 text-muted">
                                    <input v-model="remember" type="checkbox" name="remember" />
                                    <span></span>Remember me</label>
                            </div>
                            <a @click="tab = 'forgot'" class="text-muted text-hover-primary">Forgot Password ?</a>
                        </div>
                        <div class="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                            
                            <button @click="login" id="kt_login_signin_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3">Sign In</button>
                        </div>
                    </div>
                    <!--end::Form-->
                    
                </div>
                <!--end::Signin-->
                <!--begin::ResetRequest-->
                <div v-show="tab == 'forgot'" class="login-form">
                    <!--begin::Form-->
                    <div>
                        <!--begin::Title-->
                        <div class="pb-13 pt-lg-0 pt-5">
                            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Reset password</h3>
                            <span class="text-muted font-weight-bold font-size-h4"> 
                            <a @click="tab = 'login'" id="kt_login_signup" class="text-primary font-weight-bolder">Go back</a></span>
                        </div>
                        <!--begin::Title-->
                        <div class="form-group">
                            <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
                            <input v-model="reset_email" class="form-control form-control-solid h-auto py-6 px-6 rounded-lg" type="text" name="email" autocomplete="off" />
                        </div>
                        <!--end::Form group-->

                        <!--begin::Form Group-->
                        <div class="form-group">
                            <recaptcha-invisible ref="rc_reset" id="rc_reset" :sitekey="r_publickey" @callback="reset_cb"></recaptcha-invisible>
                        </div>
                        <!--end::Form Group-->
                        
                        <div class="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                            
                            <button @click="reset" id="kt_login_signin_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3">Proceed</button>
                        </div>
                    </div>
                    <!--end::Form-->
                    
                </div>
                <!--end::ResetRequest-->
                <!--begin::Signup-->
                <div v-show="tab == 'register'" class="login-form ">
                    <!--begin::Form-->
                    <form class="form fv-plugins-bootstrap fv-plugins-framework" novalidate="novalidate" id="kt_login_signup_form">
                        <!--begin::Title-->
                        <div class="pb-13 pt-lg-0 pt-5">
                            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Sign Up</h3>
                            <p class="text-muted font-weight-bold font-size-h4">Enter your details to create your account</p>
                        </div>
                        <!--end::Title-->
                       
                        <!--begin::Form group-->
                        <div class="form-group fv-plugins-icon-container">
                            <input v-model="register_email" class="form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6" type="email" placeholder="Email" name="email" autocomplete="off">
                        <div class="fv-plugins-message-container"></div></div>
                        <!--end::Form group-->
                        <!--begin::Form group-->
                        <div class="form-group fv-plugins-icon-container">
                            <input v-model="register_password" class="form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6" type="password" placeholder="Password" name="password" autocomplete="off">
                        <div class="fv-plugins-message-container"></div></div>
                        <!--end::Form group-->
                        <!--begin::Form group-->
                        <div class="form-group fv-plugins-icon-container">
                            <input v-model="register_rpassword" class="form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6" type="password" placeholder="Confirm password" name="cpassword" autocomplete="off">
                        <div class="fv-plugins-message-container"></div></div>
                        <!--end::Form group-->

                        <!--begin::Form Group-->
                        <div class="form-group">
                            <recaptcha-invisible ref="rc_register" id="rc_register" :sitekey="r_publickey" @callback="register_cb"></recaptcha-invisible>
                        </div>
                        <!--end::Form Group-->
                        <!--begin::Form group-->
                        <div class="form-group fv-plugins-icon-container">
                            <label class="checkbox mb-0">
                                <input type="checkbox" name="agree">
                                <span></span>
                                <div class="ml-2" style="color:#b5b5c3;">I Agree the 
                                <a href="#">terms and conditions</a>.</div>
                            </label>
                            
                        <div class="fv-plugins-message-container"></div></div>
                        <!--end::Form group-->
                        <!--begin::Form group-->
                        <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                            <button type="button" @click="register" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4">Submit</button>
                        </div>
                        <!--end::Form group-->
                    <div></div></form>
                    <!--end::Form-->
                </div>
                <!--end::Signup-->
                
            </div>
            <!--end::Content body-->
            <!--begin::Content footer-->
            <div class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
                <div class="text-dark-50 font-size-lg font-weight-bolder mr-10">
                    <span class="mr-1">2021©</span>
                    <a href="https://trustsignals.ai/" target="_blank" class="text-dark-75 text-hover-primary">TrustSignals</a>
                </div>
                <a href="#" class="text-primary font-weight-bolder font-size-lg">Terms of services</a>
                <a href="#" class="text-primary ml-5 font-weight-bolder font-size-lg">Contact Us</a>
            </div>
            <!--end::Content footer-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Login-->
</div>

<!--end::Main-->
</template>

<style scoped>
@import './../../assets/css/pages/login/login-1.css';
</style>

<script>
import Maurer from './../../components/Maurer.vue';
import hash from 'js-sha512';

export default {
    beforeCreate: function () {
        var vm = this;
        document.getElementById("maurer_app").className = 'd-flex flex-root';

    },

    data: function () {
        return {
            remember: false,
            tab: 'login',
            r_publickey: '6LeoyCsbAAAAAP-Jp4FNngg3ZgGRczZO-UinLDFX',
            twoFA_required: false,
            loading: false,
            login_email: '',
            login_password: '',
            register_email: '',
            register_password: '',
            register_rpassword: '',
            reset_email: '',
            twoFA: '',
            ref: undefined
        };
    },

    methods: {
        reset(){
            var vm = this;
            vm.loading = true;
            
            
            vm.$refs.rc_reset.execute();
        },

        reset_cb(token){
            var vm = this;
            Maurer.api.user.reset({
                Email: vm.reset_email,
                RecaptchaResponse: token
            }, function (d) {
                vm.loading = false;
                Maurer.api.success(d);
            }, function (d) {
                vm.loading = false;
                Maurer.api.error(d);
            });
        },


        register: function() {
            var vm = this;
            vm.loading = true;
            if (vm.register_password.length < 6) {
                Maurer.api.error(undefined, "Weak password", "Password must be at least 6 characters long.", "notify");
                vm.loading = false;
                return;
            } else if (vm.register_password != vm.register_rpassword) {
                Maurer.api.error(undefined, "Password mismatch", "Please fill in all fields and retry.", "notify");
                vm.loading = false;
                return;
            }
            
            vm.$refs.rc_register.execute();
        },


        register_cb: function (token) {
            var vm = this;
            
            Maurer.api.user.register({
                Email: vm.register_email,
                Password: hash.sha512(vm.register_password),
                RecaptchaResponse: token,
                Referer: vm.ref
            }, function (d) {
                Maurer.api.user.login({
                    Email: vm.register_email,
                    Password: hash.sha512(vm.register_password),
                    Duration: '12'
                }, function (d) {
                    localStorage.setItem("PUMPMOB_TOKEN", d.token);
                    vm.loading = false;
                    window.router.push("/");
                }, function (d) {
                    vm.loading = false;
                    if (d.responseJSON != undefined) {
                        if (d.responseJSON.status == 10302) {
                            vm.twoFA_required = true;
                        }
                    }

                    Maurer.api.error(d);
                });
            }, function (d) {
                vm.loading = false;
                Maurer.api.error(d);
            });
            
        },

        login: function () {
            var vm = this;
            vm.loading = true;
            Maurer.api.user.login({
                Email: vm.login_email,
                Password: hash.sha512(vm.login_password),
                Duration: (vm.remember ? "720" : "12"),
                TwoFA: vm.TwoFA
            }, function (d) {
                localStorage.setItem("PUMPMOB_TOKEN", d.token);
                vm.loading = false;
                window.router.push("/");
            }, function (d) {
                vm.loading = false;
                if (d.responseJSON != undefined) {
                    if (d.responseJSON.status == 10302) {
                        vm.twoFA_required = true;
                    }
                }

                Maurer.api.error(d);
            });
        }
    },

    mounted(){
        var vm = this;
        var cookie_ref = (document.cookie.match('(^|;) *ts_ref=([^;]*)')||[])[2];
        if(vm.$route.params.ref != undefined && vm.$route.params.ref != ''){ // priority to new ref url
            vm.tab = 'register';
            vm.ref = vm.$route.params.ref;
            document.cookie = "ts_ref=" + vm.ref + "; path=/; max-age=31536000";
        } else if(cookie_ref != undefined){ //otherwise if the user has a cookie
            vm.ref = cookie_ref;
        }
    }
}
</script>
